import { FC, useRef } from "react";
import JoditEditor from "jodit-react";

type TexEditorProps = {
  value: string;
  onChange: (value: string) => void;
};

const TexEditor: FC<TexEditorProps> = ({ onChange, value }) => {
  const editor = useRef(null);

  const createCaptionElement = (text: string) => {
    const figcaption = document.createElement("figcaption");
    figcaption.textContent = text;

    Object.assign(figcaption.style, {
      color: "#232944",
      fontSize: "16px",
      fontFamily: "Archivo",
      marginTop: "16px",
      marginBottom: "16px",
    });

    return figcaption;
  };

  const insertImageWithCaption = (imgElement: HTMLElement | null) => {
    if (imgElement && imgElement.parentNode) {
      const figure = document.createElement("figure");
      const figcaption = createCaptionElement("Enter caption here");

      imgElement.parentNode.insertBefore(figure, imgElement);
      figure.appendChild(imgElement);
      figure.appendChild(figcaption);
    }
  };

  return (
    <JoditEditor
      ref={editor}
      value={value}
      config={{
        readonly: false,
        placeholder: "Start typing...",
        events: {
          afterInsertImage: insertImageWithCaption,
        },
      }}
      onBlur={(newContent: string) => onChange(newContent)}
    />
  );
};

export default TexEditor;

// export default TexEditor;

// import { FC } from "react";

// import { Box, useTheme } from "@mui/material";
// import { Editor } from "@tinymce/tinymce-react";

// type TexEditorProps = {
//   value: string;
//   onChange: (value: string) => void;
// };

// const TexEditor: FC<TexEditorProps> = ({ value, onChange }) => {
//   const theme = useTheme();

//   const handleChange = (e: any) => {
//     onChange(e.target.getContent());
//   };

//   return (
//     <Box>
//       <Editor
//         apiKey="qdfqcknyl4kl3rvcxo7492zwxakhw26t5g855yjwz8t41dwk"
//         onChange={handleChange}
//         initialValue={value}
//         init={{
//           height: 700,
//           menubar: true,
//           icons: "material",
//           plugins: [
//             "advlist",
//             "autolink",
//             "lists",
//             "link",
//             "image",
//             "charmap",
//             "anchor",
//             "searchreplace",
//             "visualblocks",
//             "code",
//             "fullscreen",
//             "insertdatetime",
//             "media",
//             "table",
//             "preview",
//             "help",
//             "wordcount",
//             "image",
//             "quickimage",
//             "quicklink",
//             "quicktable",
//             "searchreplace",
//             "table",
//             "blockquote",
//             "editimage",
//             "fliph",
//             "flipv",
//             "imageoptions",
//             "rotateleft",
//             "rotateright",
//             "emoticons",
//             "media",
//             "preview",
//             "lineheight",
//           ],
//           toolbar: [
//             "undo redo  | blocks | fontsize | lineheight | bold italic forecolor blockquote |  alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | backcolor forecolor",
//             " table | charmap emoticons | image media | link  openlink unlink | removeformat | preview fullscreen | searchreplace help ",
//           ],
//           content_style: `body { font-family:${theme.typography.fontFamily} }`,
//         }}
//       />
//     </Box>
//   );
// };
